const mockData = {
    "bathroom_equipment_products": [
      {
        "product_uuid": "1835594e-8b0f-5718-858a-971dd51f7df1",
        "product_name": "Adamo Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Adamo_Series.png",
        "product_description": "Discover the epitome of elegance with the Adamo Series of Bath Cabinets, a harmonious blend of style and utility. Crafted with precision, each cabinet in this collection showcases a timeless design, marrying traditional craftsmanship with contemporary aesthetics. The Adamo Series is distinguished by its superior materials, ensuring not only enduring beauty but also remarkable durability. Its sophisticated finish and meticulous detailing embody a commitment to excellence. More than a mere storage solution, these cabinets are a luxurious statement, bringing tranquility and sophistication to your bathroom. The Adamo Series, with its sleek contours and elegant lines, is perfect for those who seek the finer things in life, transforming everyday routines into experiences of luxury and comfort. Embrace the Adamo Series for an infusion of class and opulence in your home.",
        "product_detail_images_path_list": [
          "/All_Products_images/Bath_Cabinets:浴室柜/Adamo_Series/Adamo_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Adamo_Series/Adamo_Series_D_2.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Adamo_Series/Adamo_Series_D_3.png"
        ]
      },      
      {
        "product_uuid": "7957d86c-1049-53b4-b6a4-51df1a9e79fd",
        "product_name": "Devine Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",
        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_2.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_3.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_4.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_5.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_6.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Devine_Series/Devine_Series_D_7.png",
        ]
      },
      {
        "product_uuid": "ac9b176d-2000-5584-81e3-0011b40932cf",
        "product_name": "FB-2010",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2010.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "da127cf6-13ff-5666-9710-7461653c4710",
        "product_name": "FB-2011",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2011.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "7d17c652-1c97-5211-a925-949cd494b8f0",
        "product_name": "FB-2023/B",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2023:B.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "b7045e79-dfa1-5bc1-9fc3-f362f5720e04",
        "product_name": "FB-2026A/B/C/D",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2026A:B:C:D.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "62e88460-5055-54e1-8678-9a2c60f8f17f",
        "product_name": "FB-2027/A/B",
        "product_category": "Smart Toilets",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2027:A:B.png",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "79cff92d-97b4-514b-b659-16926ab8a033",
        "product_name": "FB-2028A/B/D",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2028A:B:D.png",
        "product_category": "Smart Toilets",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "6010959d-eb36-588f-807f-59fc4e74e640",
        "product_name": "FB-2029A/B/D",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2029A:B:D.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "b58e1733-af1e-56c1-9dce-e4ef84d34e7b",
        "product_name": "FB-2084",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2084.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "e3027a1b-3fc1-5cbe-88e8-848560c69389",
        "product_name": "FB-2087A/B",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2087A:B.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "e491b3de-2923-5059-9edd-51cefb28732b",
        "product_name": "FB-2094",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-2094.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "4e16e58f-cea4-5138-9eed-67e1a2c2301d",
        "product_name": "FB-5001",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5001.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "3aa617bc-bf66-52d7-9333-c6d29536c31a",
        "product_name": "FB-5001S",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5001S.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "33fdfb85-1a6b-5f1a-9174-4e820a45da97",
        "product_name": "FB-5002B",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5002B.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "c22c20cf-0b6a-5633-9801-a9c84a9811fb",
        "product_name": "FB-5002K",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5002K.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "0279bba5-a9a2-5785-8d9c-0fb2c21cf528",
        "product_name": "FB-5003",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5003.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "ace55a68-081c-5506-a646-f274748e012f",
        "product_name": "FB-5004",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5004.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "b746dd3c-3b67-59e4-89bc-dc9c05b17cb1",
        "product_name": "FB-5005",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5005.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "b4b0efeb-7a7c-531b-8df3-ad1442f21fcc",
        "product_name": "FB-5006K-L/R",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5006K-L:R.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "aea8ab44-e690-5957-84d9-f5c10e595341",
        "product_name": "FB-5008W/R",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5008W:R.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "f9a00f2d-26e6-50d6-9229-a8c7e17dace8",
        "product_name": "FB-5009F",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5009F.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "96849577-958d-52b1-a323-aae2461ea984",
        "product_name": "FB-5010",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5010.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "900a7970-6b36-52a8-8197-17d043948f6a",
        "product_name": "FB-5022",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-5022.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "5cf0cbe9-13ac-5d6b-82b6-1c4a3d64512d",
        "product_name": "FB-ALISE",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-ALISE.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "cc76abc4-2aa2-58a5-8dd9-b6d5f80d708a",
        "product_name": "FB-AUSTIN",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-AUSTIN.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "742ebb9b-b544-5827-b08b-2c7ac2336d1f",
        "product_name": "FB-CHLOE",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-CHLOE.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "53b78043-44a5-5f93-a970-5504302ce262",
        "product_name": "FB-Dignifiend Lady Bathtub",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-Dignifiend.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "0894a4af-2430-5074-a26e-7652dd571564",
        "product_name": "FB-G1901/B",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-G1901:B.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "837a9ddc-5cc8-586e-9e07-69ffd0e6674e",
        "product_name": "FB-G1903",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-G1903.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "20c3571a-42bb-5cce-849e-9ca9b22cbf9d",
        "product_name": "FB-LEBLANC",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-LEBLANC.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "5086c830-1e56-549c-a3c9-1f0708fc2969",
        "product_name": "FB-RQSX19",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-RQSX19.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "f4ed943f-282e-53a1-9f76-f6a0ad216620",
        "product_name": "FB-SEVILLA",
        "product_category": "Bathtubs",
        "product_image_path": "/All_Products_images/Bathtubs:浴缸/FB-SEVILLA.png",
        "product_description": "Our Bathtubs collection is a sanctuary of relaxation, designed to provide an escape from the hustle of everyday life. Each bathtub is a masterpiece, meticulously crafted to cradle you in comfort. The elegant contours and sumptuous depths invite you to immerse in a soothing retreat, enveloped in warmth and serenity. These bathtubs are not just fixtures; they are the centerpiece of any bathroom, a haven for rejuvenation. The range of designs, from sleek modern minimalism to opulent traditional elegance, ensures there is a bathtub to suit every taste and décor. Premium materials and superior craftsmanship guarantee a luxurious experience and enduring beauty. The Bathtubs collection is a celebration of self-care, where each soak is a transformative experience, whisking you away to a world of tranquility.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "1db7954c-9377-55ca-a40c-28a757c6158b",
        "product_name": "FB-ZN07A",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-ZN07A.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "ca73ee4e-6ae3-54c7-b10e-dcb8eeeb2ca8",
        "product_name": "FB-ZN08A",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-ZN08A.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "39d8569b-0646-53a9-a137-99599dd18e75",
        "product_name": "FB-ZN08B",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-ZN08B.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "51a70c10-7033-5f6e-9568-c5d8b3f03904",
        "product_name": "FB-ZN09A",
        "product_category": "Smart Toilets",
        "product_image_path": "/All_Products_images/Smart_Toilets:智能厕所/FB-ZN09A.png",
        "product_description": "Step into the future of bathroom luxury with our Smart Toilets. These state-of-the-art creations redefine the boundaries of comfort and hygiene with their innovative features. Equipped with intelligent sensors, customizable settings, and eco-friendly technology, each smart toilet in our collection offers a bespoke bathroom experience. The advanced cleansing systems ensure impeccable hygiene, while the ergonomic design provides unmatched comfort. With features like heated seats, automatic lids, and personalized wash settings, these toilets are a testament to modern innovation. The sleek, minimalist design of each smart toilet blends seamlessly into any bathroom, adding a touch of sophistication. Embrace the convergence of technology and luxury with our Smart Toilets, where every visit is a journey to unparalleled comfort and convenience.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "41849635-561a-5079-9839-fbebaa9fc51b",
        "product_name": "Nesdal Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",
        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_2.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_3.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_4.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_5.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_6.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nesdal_Series/Nesdal_Series_D_7.png",
        ]
      },
      {
        "product_uuid": "5f4da1c8-9fa5-5c2d-9812-a0e2ccaec4bf",
        "product_name": "Nyman Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Nyman_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",

        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Nyman_Series/Nyman_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Nyman_Series/Nyman_Series_D_2.png",
        ]
      },
      {
        "product_uuid": "f53df436-bc3f-5183-ac2a-440bc415ac32",
        "product_name": "Oiva Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",

        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_2.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_3.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_4.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_5.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_6.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Oiva_Series/Oiva_Series_D_7.png",
        ]
      },
      {
        "product_uuid": "935671fb-881d-5e9d-8ff2-14a164b1a42d",
        "product_name": "RB Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RB_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "d70edb0e-0094-56d2-a57f-349332078ac8",
        "product_name": "RC Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RC_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "dc9ab739-fe3e-5867-885d-4971078df801",
        "product_name": "RD Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RD_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "75ed37e8-2821-5139-b11e-872c709ac134",
        "product_name": "RE Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RE_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "b547f355-9aea-5203-aaad-2e3dcffe62b1",
        "product_name": "RH Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RH_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "8acad443-3bf7-542f-9938-47c04e68b902",
        "product_name": "RI Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RI_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "a91d7bca-263b-5554-a691-66fee162141d",
        "product_name": "Risom Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Risom_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",

        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Risom_Series/Risom_Series_D_1.png",
        ]
      },
      {
        "product_uuid": "1c3d2ded-79fe-572c-b722-08e6aec9da60",
        "product_name": "RJ Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RJ_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "6192e7ee-98f3-5c3e-92a3-36eda7f68695",
        "product_name": "RP Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RP_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "e459011a-8bf1-555e-bd48-bf33db3b3c8d",
        "product_name": "RS Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RS_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "1559eebd-f06e-5416-92b7-1b8a1d0b16cd",
        "product_name": "RW Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RW_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "9ba17285-b041-5d90-9163-a30e42e48c6d",
        "product_name": "RX Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RX_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "cc9853db-2038-5972-a3c9-356b4076ac0b",
        "product_name": "RY Series",
        "product_category": "Showers",
        "product_image_path": "/All_Products_images/Showers:淋浴/RY_Series.png",
        "product_description": "Experience the invigorating essence of our Showers collection, where each shower transforms daily rituals into a rejuvenating experience. Our showers are designed to provide a sensory escape, with options ranging from gentle rainfall to powerful jets, all tailored to your preference. The innovative technology behind each shower ensures consistent water flow and temperature, enveloping you in comfort. The sleek designs, from contemporary to classic, enhance the aesthetic of any bathroom, turning it into a personal oasis. The durable materials and quality construction promise a lasting experience, making every shower a refreshing and energizing encounter. The Showers collection offers a perfect blend of luxury, performance, and style, ensuring each moment under the water is a revitalizing journey for the senses.",
        "product_detail_images_path_list":  []
      },
      {
        "product_uuid": "30626983-f45a-5712-a77d-0c2a93811275",
        "product_name": "Viggo Series",
        "product_category": "Bath Cabinets",
        "product_image_path": "/All_Products_images/Bath_Cabinets:浴室柜/Viggo_Series.png",
        "product_description": "The Bath Cabinets collection presents an exquisite amalgamation of functionality and artistry. These cabinets are more than mere storage solutions; they are the heart of bathroom elegance, crafted to transform ordinary spaces into realms of luxurious tranquility. Each piece is a tribute to fine craftsmanship, boasting the finest materials and intricate designs that speak volumes of sophistication. The exquisite finishes, ranging from classic wood grains to contemporary high-gloss, are carefully chosen to complement any bathroom aesthetic. The cabinets offer ample space, seamlessly integrating with your daily routine while elevating the overall ambiance. Their enduring construction promises longevity, standing as timeless icons of style. The Bath Cabinets collection is an invitation to indulge in a sanctuary of serenity, where every detail is a testament to the pursuit of perfection in bathroom design.",
        "product_detail_images_path_list":  [
          "/All_Products_images/Bath_Cabinets:浴室柜/Viggo_Series/Viggo_Series_D_1.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Viggo_Series/Viggo_Series_D_2.png",
          "/All_Products_images/Bath_Cabinets:浴室柜/Viggo_Series/Viggo_Series_D_3.png",
        ]
      },
      {
        "product_uuid": "6ea3b099-0f76-490e-8013-c44d44afa306",
        "product_name": "FB-3027",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3027.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "554cc0a3-2940-4ae0-bb22-1b69f52100e9",
        "product_name": "FB-3029",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3029.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "2710758d-d41e-41cb-8e5e-2db104587c00",
        "product_name": "FB-3030",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3030.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "d914a143-2cae-46c1-bbb5-cdb996410333",
        "product_name": "FB-3031",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3031.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "9d719f4f-e1fd-46b0-a8a2-7ae75cbfaf9b",
        "product_name": "FB-7726",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-7726.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "02d6cbcc-18d2-46c4-8818-e6637ed88c06",
        "product_name": "FB-7726A",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-7726A.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "6596b4d8-5354-40b8-b860-5cda3872b1d1",
        "product_name": "FB-7727",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-7727.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "d2f3f01a-7f8d-41ae-8e25-b67aeddbe138",
        "product_name": "FB-7728",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-7728.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },   
      {
        "product_uuid": "e53a6659-967d-4f62-9dc6-a8ecb3bd9c50",
        "product_name": "FB-3036",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3036.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "83be6be3-8a7c-4d8a-bb99-c90d6b1b897f",
        "product_name": "FB-3037",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3037.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "523de7fe-fc7f-4553-b9e7-22526c19f862",
        "product_name": "FB-3038",
        "product_category": "Shower System",
        "product_image_path": "/All_Products_images/Shower_System:淋浴花洒/FB-3038.png",
        "product_description": "Immerse yourself in the epitome of luxury with our state-of-the-art Shower System. Engineered with precision, this exquisite ensemble promises an unparalleled bathing experience. Its sleek, contemporary design, crowned with a lustrous chrome finish, transforms your bathroom into a sanctuary of elegance. At the heart of this system lies an advanced thermostatic control, ensuring a consistent, blissful temperature, tailored to your preference. The rainfall showerhead, a masterpiece in itself, cascades a gentle, soothing deluge, reminiscent of nature's tender touch. Complemented by an array of adjustable, high-pressure body jets, it envelops you in a cocoon of warmth, offering a therapeutic, spa-like retreat. Eco-friendly technology harmonizes efficiency with indulgence, minimizing water usage without compromising on the luxurious cascade. Our Shower System isn't just an addition to your bathroom; it's a celebration of sophistication, a tribute to modern luxury living.",
        "product_detail_images_path_list": []
      },   
      {
        "product_uuid": "edc3d1e0-9882-429c-bbcc-9c724cfa806a",
        "product_name": "FB-61176",
        "product_category": "Toilets",
        "product_image_path": "/All_Products_images/Toilets:马桶/FB-61176.png",
        "product_description": "Discover the zenith of bathroom innovation with our premium Toilet Collection. Each piece is a harmonious blend of art and functionality, sculpted to elevate the essence of your personal sanctuary. Crafted from the finest vitreous china, our toilets boast an immaculate, glossy finish that exudes timeless elegance. The seamless, curvilinear design not only captivates the eye but also ensures effortless cleaning, epitomizing the fusion of aesthetics and practicality. At the core of this splendid creation is pioneering, eco-efficient flush technology. It delivers a powerful, whisper-quiet flush while conservatively managing water resources, reflecting our commitment to environmental stewardship. The ergonomically designed bowl promises utmost comfort, complemented by a gently closing, antibacterial seat that upholds the highest standards of hygiene. Embrace the epitome of luxury and sustainability with our Toilet Collection, where each visit becomes an indulgent experience in refined sophistication.",
        "product_detail_images_path_list": []
      },    
      {
        "product_uuid": "c365bd70-190f-421b-b18c-3480d52b9fad",
        "product_name": "FB-61177",
        "product_category": "Toilets",
        "product_image_path": "/All_Products_images/Toilets:马桶/FB-61177.png",
        "product_description": "Discover the zenith of bathroom innovation with our premium Toilet Collection. Each piece is a harmonious blend of art and functionality, sculpted to elevate the essence of your personal sanctuary. Crafted from the finest vitreous china, our toilets boast an immaculate, glossy finish that exudes timeless elegance. The seamless, curvilinear design not only captivates the eye but also ensures effortless cleaning, epitomizing the fusion of aesthetics and practicality. At the core of this splendid creation is pioneering, eco-efficient flush technology. It delivers a powerful, whisper-quiet flush while conservatively managing water resources, reflecting our commitment to environmental stewardship. The ergonomically designed bowl promises utmost comfort, complemented by a gently closing, antibacterial seat that upholds the highest standards of hygiene. Embrace the epitome of luxury and sustainability with our Toilet Collection, where each visit becomes an indulgent experience in refined sophistication.",
        "product_detail_images_path_list": []
      },        
    ]
  }

  export default mockData;