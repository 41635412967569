import React, { useContext } from 'react';
import { useParams, Navigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import mockData from '../__data__/mockData'; 
import { ThemeContext } from '../ThemeContext';

import { useSpring, animated } from 'react-spring';


const ProductDetailPage = () => {
    const { theme } = useContext(ThemeContext);

    const { productUuid } = useParams();
    const product = mockData.bathroom_equipment_products.find(p => p.product_uuid === productUuid);

    const fade = useSpring({ 
        from: { opacity: 0, transform: 'translateY(12px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        delay: 0,
        config: { duration: 100 }
    });


    return (
        <animated.div style={fade}>
            <div className="container-fluid p-0 d-flex flex-column justify-content-center" style={{ fontFamily:theme.fontFamily, height: '100%', minHeight: 'calc(100vh - 160px)', backgroundColor: theme.backgroundColor, color: theme.buttonTextColor, width: '100%', maxWidth: '100%'}}>
                
                <div className="col-12 col-md-8 col-lg-6 mx-auto p-4">
                    {/* Breadcrumb */}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{ backgroundColor: theme.backgroundColor, padding: '20px 0px' }}>
                            <li className="breadcrumb-item">
                                <Link to="/" style={{ textDecoration: 'none', color: theme.buttonTextColor }}>Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/products" style={{ textDecoration: 'none', color: theme.buttonTextColor }}>Products</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page" style={{ color: theme.buttonTextColor }}>
                                {product.product_name}
                            </li>
                        </ol>
                    </nav>
                    
                    <div className="col align-self-center" style={{ fontSize:"22px", fontWeight:"bold", marginBottom: '20px' }}>
                        Category
                    </div>
                    <div className="category-tag" style={{ marginBottom: '30px',}}> 
                        {product.product_category}
                    </div> 

                    {/* Product name */}
                    <div className="col align-self-center" style={{ fontSize:"22px", fontWeight:"bold", marginBottom: '20px' }}>
                        Product Name
                    </div>
                    <h1 className="text-left" style={{ fontSize:"14px",  marginBottom: '14px', marginBottom: '30px'  }}>{product.product_name}</h1>

                    <div className="col align-self-center" style={{ fontSize:"22px", fontWeight:"bold", marginBottom: '20px' }}>
                        Description
                    </div>
                    
                    {/* Product descriptions */}
                    <div style={{ ...theme.ProductDetailPage.descriptionStyle, marginBottom: '30px' }}>
                        <p style={{ fontSize:"14px",}}>
                            {product.product_description}
                        </p>
                    </div>

                    <div className="col align-self-center" style={{ fontSize:"22px", fontWeight:"bold", marginBottom: '20px' }}>
                        Images
                    </div>

                    {/* Product image */}
                    <div className="text-center" style={ theme.ProductDetailPage.imageStyle }>
                        <img 
                            src={product.product_image_path} 
                            alt="Product" 
                            className="img-fluid" 
                        />
                    </div>

                    {/* Render product detail images */}
                    {product.product_detail_images_path_list.map((imagePath, index) => (
                        <div key={index} className="text-center" style={ theme.ProductDetailPage.imageStyle }>
                            <img 
                                src={imagePath} 
                                alt={`Product Detail ${index + 1}`} 
                                className="img-fluid" 
                            />
                        </div>
                    ))}
                </div>


            </div>
        </animated.div>
    )
};

export default ProductDetailPage;
