import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import mockData from '../__data__/mockData';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../ThemeContext';
import '../css/product.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { useSpring, animated } from 'react-spring';



const ProductsPage = () => {
	const { theme } = useContext(ThemeContext);
	
    const [search, setSearch] = useState('');
	const [selectedCategories, setSelectedCategories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

	const location = useLocation();

    const fade = useSpring({ 
        from: { opacity: 0, transform: 'translateY(12px)' },
        to: { opacity: 1, transform: 'translateY(0)' },
        delay: 0,
        config: { duration: 100 }
    });

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
          });
	  }, [location]);

    // Extract unique categories
    useEffect(() => {
        const uniqueCategories = Array.from(new Set(mockData.bathroom_equipment_products.map(p => p.product_category)));
        setCategories(uniqueCategories);
    }, []);
	
    // Handle category selection
    const handleCategorySelection = (category) => {
        setSelectedCategories(prevSelected => {
            if (prevSelected.includes(category)) {
                return prevSelected.filter(c => c !== category);
            } else {
                return [...prevSelected, category];
            }
        });
        setCurrentPage(1);
    };

    // Filter data based on search input and categories
    const filteredData = mockData.bathroom_equipment_products.filter(product =>
        (
            search === '' || 
            product.product_name.toLowerCase().includes(search.toLowerCase()) ||
            product.product_description.toLowerCase().includes(search.toLowerCase()) ||
            product.product_image_path.toLowerCase().includes(search.toLowerCase()) ||
            product.product_category.toLowerCase().includes(search.toLowerCase()))
            &&
            (selectedCategories.length === 0 || selectedCategories.includes(product.product_category)
        )
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for a smooth scrolling
        });
    };

    // Function to handle page change
    const paginate = (pageNumber) => {
        // Your existing logic to change the page
        setCurrentPage(pageNumber);
    
        // Scroll to the top of the page
        scrollToTop();
    };

	const [activeIndex, setActiveIndex] = useState(0);
    const totalCards = 4; // Adjust based on your total number of cards

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((current) => (current + 1) % totalCards);
        }, 3000); 

        return () => clearInterval(interval);
    }, [totalCards]);

	const cardImages = [
		'/Image_Products_Page/slider_1_**.jpg',
		'/Image_Products_Page/slider_3.jpg',
		'/Image_Products_Page/slider_2.jpg',
        '/Image_Products_Page/slider_1.png',
    ];

    const cardText = [
        'Showers',
        'Bathtubs',
        'Smart Toilets',
        'Bath Cabinets'
    ]

    const [hoveredCard, setHoveredCard] = useState(null);

    // Function to apply hover style
    const applyHoverStyle = (product, index) => {
        const baseStyle = {
            ...theme.productStyle,
            backgroundImage: `url(${product.product_image_path})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease' // Ensure transition is included
        };
    
        if (index === hoveredCard) {
            return {
                ...baseStyle,
                transform: 'scale(1.05)',
                boxShadow: '0 10px 16px 0 rgba(0, 0, 0, 0.4)'
            };
        }
        return baseStyle;
    };
    

	return (
        <animated.div style={fade}>
            <div className="container-fluid p-0 d-flex flex-column justify-content-center" style={{ fontFamily:theme.fontFamily, height: '100%', minHeight: 'calc(100vh - 160px)', backgroundColor: theme.backgroundColor, color: theme.buttonTextColor, width: '100%', maxWidth: '100%'}}>
                <div className="card-container">
                    {Array.from({ length: totalCards }).map((_, index) => (
                        <div className={`card ${index === activeIndex ? 'active' : ''}`} key={index} style={{ '--card-bg-image': index === activeIndex && `url(${cardImages[index]})` }}>
                            <div className="card-overlay">
                                <div  className="card-text">
                                    {`${cardText[index]}`}
                                </div>
                                <img 
                                    src={cardImages[index]} 
                                    style={{ zIndex: 1, maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} // Or use 'contain'
                                />
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{ margin: '30px' }}>
                    <div className="row mb-4">
                        <div className="col-12 col-md-4 col-lg-3" style={{ fontSize:"28px", fontWeight:"bold",}}>
                            All Products
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-md-4 col-lg-3">
                            <input
                                className="form-control"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    setCurrentPage(1); // Reset to first page on search
                                }}
                            />
                        </div>
                        <div className="col align-self-center" style={{ fontSize:"16px", paddingTop: "8px" }}> 
                            {/* <!-- Adjust the column size as needed --> */}
                            Found {filteredData.length} results
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="col align-self-center" style={{ fontSize:"20px", fontWeight:"bold", marginBottom: '20px' }}> Filter by Category</div>
                            {categories.map(category => (
                                <div key={category} className="form-check d-flex align-items-center" style={{ marginBottom: '8px' }}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value={category}
                                        id={`category-${category}`}
                                        checked={selectedCategories.includes(category)}
                                        onChange={() => handleCategorySelection(category)}
                                        style={{height: '20px', width: '20px', marginRight: '10px', marginTop: '0px'}}
                                    />
                                    <label className="form-check-label" htmlFor={`category-${category}`} style={{ fontSize:"16px", }}>
                                        {category}
                                    </label>
                                </div>
                            ))}
                        </div>

                        <div className="col-12 col-lg-9 mt-3 mt-lg-0">
                            <div className="row">
                                {currentItems.map((product, index) => (
                                    <div className="col-6 col-md-4 col-lg-3 mb-4" key={product.product_uuid} onMouseEnter={() => setHoveredCard(index)} onMouseLeave={() => setHoveredCard(null)}>
                                        <div style={applyHoverStyle(product, index)} className="product-card p-0 d-flex flex-column align-items-center justify-content-center">
                                            <img 
                                                src={product.product_image_path} 
                                                alt={product.product_name} 
                                                style={{ zIndex: 999, maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} // Or use 'contain'
                                            />
                                        </div>
                                        <div className="text-left" style={{ marginTop: '28px', fontSize: '14px', cursor: 'pointer' }}>
                                            {product.product_name}
                                        </div>                                       
                                        <div className="view-details-link" style={{fontSize: '12px'}}>
                                            {/* <Link to={`/product_detail/${product.product_uuid}`}><FontAwesomeIcon icon={faEye} style={{}}/>View Details</Link> */}
                                            <a href={`/product_detail/${product.product_uuid}`} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faEye} style={{}}/> View Details
                                            </a>
                                        </div>
                                        <div className="category-tag" style={{ marginTop: '8px' }} >{product.product_category}</div> 
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <nav aria-label="Page navigation example" style={{ marginTop: '120px', marginBottom: '80px' }}>
                        <ul className="pagination justify-content-center custom-pagination">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); paginate(currentPage - 1); }}>Previous</a>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li key={index} className={`page-item ${index + 1 === currentPage ? 'active custom-active' : ''}`}>
                                    <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); paginate(index + 1); }}>{index + 1}</a>
                                </li>
                            ))}
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                <a className="page-link" href="#" onClick={(e) => { e.preventDefault(); paginate(currentPage + 1); }}>Next</a>
                            </li>
                        </ul>
                    </nav>

                </div>
            </div>
        </animated.div>
	);
};

export default ProductsPage;
