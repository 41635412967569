import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

import ReactGA from 'react-ga';

ReactGA.initialize('G-XG8J6RQP5L'); // Replace 'YOUR_TRACKING_ID' with your GA tracking ID.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(sendToVercelAnalytics);
reportWebVitals(SendAnalytics);