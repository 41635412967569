import React, { createContext, useState } from 'react';

const defaultTheme = {
	navStyle: {
		backgroundColor: '#18183B',
		color: '#EDEDE4',
	},
	fontFamily: 'Martel',


	backgroundColor: '#FEF4DC',
	textColor: '#FEF4DC',
	buttonColor: '#EBDCB0', // example for a yellow button
	buttonTextColor: "#181E37",

	productStyle: {
		height: '300px', // Set minimum height for product image
		width: '100%', // Full width of the column
		//backgroundColor: 'white', // Placeholder color for the image
		marginBottom: '10px', // Space below the product image
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden', // This ensures that nothing overflows the container
		borderRadius: '4px', // Adjust as needed
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', // Example shadow
		transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition
	},
	
	ProductDetailPage: {
		height: '100vh',
		overflowY: 'scroll', // Allows content to scroll if it overflows 100vh
		imageStyle: {
			minHeight: '300px', // Set a minimum height for the image container
		}, 
		descriptionStyle: {
			minHeight: '100px', // Set a minimum height for the description container
		}
	},

	footerStyle: {
		backgroundColor: '#18183B',
		color: '#EDEDE4'
	}
};

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState(defaultTheme);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			{children}
		</ThemeContext.Provider>
	);
};
