import React, { createContext, useState } from 'react';

const defaultImages = {
  logo: '/images/empire_bath_logo.png',
  homePageImage: '/images/HomePage_image.png', 
  homePageImage2: '/images/HomePage_image2.png',
  contactPageImage: '/images/ContactPage_image.png', 
  // ... add more images as needed
};

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState(defaultImages);

  return (
    <ImageContext.Provider value={{ images, setImages }}>
      {children}
    </ImageContext.Provider>
  );
};
