import React, { useContext, useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeContext } from '../ThemeContext'; 
import '../css/home.css'
import { useNavigate, useLocation } from 'react-router-dom'; // Import useHistory
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ReactGA from 'react-ga';


const HomePage = () => {
	const { theme } = useContext(ThemeContext);
	const [appear, setAppear] = useState(false);
	const navigate = useNavigate(); // Initialize useHistory hook
	const heroRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
		ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
          });
	  }, [location]);

    useEffect(() => {
        setAppear(true);
    }, []);


	// Function to handle button click
    const navigateToProducts = () => {
        // navigate('/products/'); // Navigate to /products/ page
		window.open('/products/', '_blank');
    };
	
	const handleMouseMove = (e) => {
        const { clientX, clientY } = e;
        const { left, top, width, height } = heroRef.current.getBoundingClientRect();
        const x = ((clientX - left) / width) * 100;
        const y = ((clientY - top) / height) * 100;
        heroRef.current.style.setProperty('--mouse-x', `${x}%`);
        heroRef.current.style.setProperty('--mouse-y', `${y}%`);
    };

	
	return (
		<div className="container-fluid p-0"  style={{ backgroundColor: theme.backgroundColor, color: theme.textColor, height: 'calc(100vh - 160px)', width: '100%', minHeight: '720px' }}>
			<div className="hero-background" ref={heroRef} onMouseMove={handleMouseMove}>
				<div className="dark-overlay"></div>
				<div className="hero-text" style={{  color: theme.textColor, marginTop: '20px' }}>
					<h1 style={{ fontFamily: theme.fontFamily, fontWeight: 'bold', fontSize: '56px' }}>Discover Your Dream Bathroom at Empire Bath</h1>
					<p className={`${appear ? "appear" : ""}`} style={{ fontFamily: theme.fontFamily, fontSize: '20px', marginTop: '20px'}}>Where Style Meets Serenity</p>
					<button 
						className="btn mt-5" 
						onClick={navigateToProducts} // Set the onClick event
						style={{ fontWeight: 'bold', fontFamily: theme.fontFamily, backgroundColor: theme.buttonColor, color: theme.buttonTextColor, fontSize: '14px', height: "40px"}}
					>
						<FontAwesomeIcon icon={faSearch}style={{fontSize: '14px', marginRight: '8px' }}/>Explore Our Collection
					</button>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
