import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import HomePage from './components/HomePage.js';
import ProductsPage from './components/ProductsPage.js';
import ProductDetailPage from './components/ProductDetailPage.js';
import ContactPage from './components/ContactPage.js';

import { ThemeProvider } from './ThemeContext';
import { ImageProvider } from './ImageContext';
import Navbar from './Navbar'
import Footer from './Footer';
import NotFoundPage from './NotFoundPage.js';

import { SpeedInsights } from '@vercel/speed-insights/react';


function App() {
	return (
		<ImageProvider>
			<ThemeProvider>
				<Router>
					<div className="d-flex flex-column h-100">
						<Navbar/> {/* Sticky Navbar at the top */}
						<Routes>
							<Route path="/" element={
								<>
									<HomePage />
									<ContactPage />
								</>
							} />
							<Route path="/products" element={<ProductsPage />} />
							<Route path="/product_detail/:productUuid" element={<ProductDetailPage />} />
							<Route path="/contact" element={<ContactPage />} />
                        	<Route path="*" element={<Navigate to="/404" replace />} />
							<Route path="/404" element={<NotFoundPage />} />
						</Routes>
						<Footer/> 
						<SpeedInsights />
					</div>
				</Router>
			</ThemeProvider>
		</ImageProvider>
	);
}

export default App;
