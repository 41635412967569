import React, { useContext } from 'react';
import { ThemeContext } from './ThemeContext'; 


const Footer = () => {
    const { theme } = useContext(ThemeContext);

    return (
        <footer className="d-flex justify-content-center align-items-center" style={{ backgroundColor: theme.footerStyle.backgroundColor, height: '60px', width: '100%', color: theme.footerStyle.color, }}>
            <p className="text-center"  style={{ color: theme.navStyle.color, fontFamily: theme.fontFamily, fontWeight: 'normal', fontSize: '11px', marginBottom: '0px' }}>
                Copyright © 2023 by EMPIRE BATH Inc.<br/>
                Website provided by
                <a href="https://imxiaow.github.io/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: theme.footerStyle.color, }}> XW</a> @
                <a href="https://www.infinityvc.ca/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: theme.footerStyle.color, }}> Infinity Vision Consulting Inc.</a>
            </p>
        </footer>
    );
};

export default Footer;
