import React, { useContext, useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeContext } from './ThemeContext'; 
import { Link } from "react-router-dom";


const NotFoundPage = () => {
    const { theme } = useContext(ThemeContext);

    const notFoundStyle = {
        textAlign: 'center',
    };

    const headingStyle = {
        fontSize: '48px',
        fontWeight: 'bold',
        color: '#333'
    };

    const textStyle = {
        fontSize: '20px',
        margin: '20px 0',
        color: '#666'
    };

    const linkStyle = {
        textDecoration: 'none',
        color: '#007bff',
        fontSize: '18px'
    };


    return (
		<div className="container-fluid p-0"  style={{ fontFamily: theme.fontFamily, backgroundColor: theme.backgroundColor, color: theme.buttonTextColor, height: 'calc(100vh - 160px)', width: '100vw', ...notFoundStyle }}>
            <h1 style={{...headingStyle, marginTop:'100px'}}>404 - Not Found</h1>
            <p style={textStyle}>Sorry, the page you are looking for does not exist.</p>
            <Link to="/" style={linkStyle}>Go Back to Home</Link>
        </div>
    );
}

export default NotFoundPage;
