import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faClock, faHome, faCopy } from '@fortawesome/free-solid-svg-icons';

import qrCodeImage from '../EM_QR.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeContext } from '../ThemeContext'; 
import '../css/contact.css'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const ContactPage = () => {
	const { theme } = useContext(ThemeContext);
	const email = "empirebath@gmail.com";
	const phoneNumber = "(905) 604-3909";
    const phoneNumberTwo = "(647) 505-7758"
    const address = "615 Denison St Unit 3,\nMarkham, ON\nL3R 1B8";

    // State to manage QR code visibility
    const [showQR, setShowQR] = useState(false);
    
    // Function to toggle QR code visibility
    const toggleQRCode = () => {
        setShowQR(!showQR);
    };

	const location = useLocation();

	useEffect(() => {
		ReactGA.pageview(location.pathname + location.search);
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
          });
	  }, [location]);
	  
    // State for showing feedback message
    const [phoneNumberfeedback, setPhoneNumberFeedback] = useState('');
    const [emailfeedback, setEmailFeedback] = useState('');

    const copyToClipboardWithFeedback = useCallback((text, message) => {
        navigator.clipboard.writeText(text);
        if(text === phoneNumber){
            setPhoneNumberFeedback(message)
            setTimeout(() => setPhoneNumberFeedback(''), 500); // Reset feedback message after 2 seconds

        } else if (text === email) {
            setEmailFeedback(message)
            setTimeout(() => setEmailFeedback(''), 500); // Reset feedback message after 2 seconds
        }
    }, []);

    // Tooltip render function
    const renderTooltip = (props, message) => (
        <Tooltip id="button-tooltip" {...props}>
            {message}
        </Tooltip>
    );

    const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;


	return (
		<div className="container-fluid p-0"  style={{ fontFamily: 'Martel', backgroundColor: theme.backgroundColor, color: theme.buttonTextColor, height: 'calc(100vh - 160px)', width: '100%', minHeight: '720px' }}>
			<div className="hero-background-contact">
				<div className="dark-overlay-contact"></div>
                <div className="hero-text-contact" style={{  color: theme.buttonTextColor, fontWeight: 'bold', fontSize: '16px' }}>
                    {/* Add instructions text here */}
                    <div style={{ textAlign: 'center', fontSize: '10px', color: theme.buttonTextColor, fontWeight: 'bold',}}>
                        Click on the text or icon to interact <br/>(e.g. call, email, copy to clipboard)
                    </div> 
                    <div style={{ fontSize: '20px', marginTop: '40px' }}>
                        <FontAwesomeIcon icon={faHome} style={{fontSize: '16px'}}/> Address<br/>
                    </div>
                    <p style={{ color: '#181E37',  fontWeight: 'normal', marginTop: '10px'}}>
                        <a href={googleMapsLink} target="_blank" rel="noopener noreferrer"  style={{ color: '#181E37', textDecoration: 'none', fontWeight: '600',  }}>
                            {address.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ fontSize: '14px', color: '#181E37', textDecoration: 'none', fontWeight: '600', backgroundColor: 'white', padding: '5px', }}>
                                        {line}
                                    </div>
                                </React.Fragment>
                            ))}
                        </a>
                    </p>

                    <div style={{ fontSize: '20px', marginTop: '20px' }}>
                        <FontAwesomeIcon icon={faPhone} style={{fontSize: '16px', marginRight: '6px' }} /> 
                            Phone
                            {/* For each clickable item, wrap it with OverlayTrigger for tooltip */}
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={(props) => renderTooltip(props, "Click to copy phone number")}
                            >
                                <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboardWithFeedback(phoneNumber, "Copied!")} style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer', color: theme.buttonTextColor}} />
                            </OverlayTrigger>	
                             {/* Feedback message */}
                             {phoneNumberfeedback && <span style={{ color: 'green',  fontSize: '10px', fontWeight: 'bold', marginLeft: '8px' }}>{phoneNumberfeedback}</span>}
                            <br/> 
                    </div>

                    <p style={{ color: '#181E37', fontWeight: 'normal', marginTop: '10px' }}>
                        <a href={`tel:${phoneNumber}`} style={{  fontSize: '14px', color: '#181E37', textDecoration: 'none', fontWeight: '600', backgroundColor: 'white', padding: '5px', }}>
                            {phoneNumber}
                        </a>
                    </p>
                    <p style={{ color: '#181E37', fontWeight: 'normal', marginTop: '10px' }}>
                        <a href={`tel:${phoneNumberTwo}`} style={{  fontSize: '14px', color: '#181E37', textDecoration: 'none', fontWeight: '600', backgroundColor: 'white', padding: '5px', }}>
                            {phoneNumberTwo}
                        </a>
                    </p>
                    <div style={{ fontSize: '20px', marginTop: '20px'}}>
                        <FontAwesomeIcon icon={faEnvelope} style={{fontSize: '16px', marginRight: '6px'}}/> 
                        Email
                        {/* For each clickable item, wrap it with OverlayTrigger for tooltip */}
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => renderTooltip(props, "Click to copy email")}
                        >
                            <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboardWithFeedback(email, "Copied!")} style={{ fontSize: '14px', marginLeft: '10px', cursor: 'pointer', color: theme.buttonTextColor }} />
                        </OverlayTrigger>	
                        {/* Feedback message */}
                        {emailfeedback && <span style={{ color: 'green',  fontSize: '10px', fontWeight: 'bold', marginLeft: '8px' }}>{emailfeedback}</span>}                        
                        <br/>
                    </div>

                    <p style={{ color: '#181E37', fontWeight: 'normal', marginTop: '10px'}}>
                        <a href={`mailto:${email}`} style={{  fontSize: '14px', color: '#181E37', textDecoration: 'none', fontWeight: '600', backgroundColor: 'white', padding: '5px',  }}>
                            {email}
                        </a>
                    </p>
                    <div className="business-hours" style={{ color: '#181E37', marginTop: '20px' }}>
                        <div style={{ fontSize: '20px' }}>
                            <FontAwesomeIcon icon={faClock} style={{fontSize: '16px'}}/> Business Hours<br/>
                        </div>
                        <p style={{  fontSize: '14px', color: '#181E37',  fontWeight: 'normal', marginTop: '10px' }}>
                            Mon - Sat 8:00 am to 6:00 pm <br/>
                            Sunday Closed
                        </p>
                    </div>
                    <img src={qrCodeImage} alt="WeChat QR Code" style={{ width: '80px', height: '80px' }} />
                </div>  
			</div>
		</div>
	);
};

export default ContactPage;
