import React, { useContext, useState } from 'react';
import { ThemeContext } from './ThemeContext'; 
import './css/nav.css'
import { ImageContext } from './ImageContext'; 


const Navbar = () => {
	const { theme } = useContext(ThemeContext);
	const { images } = useContext(ImageContext);


    const [isCollapsed, setIsCollapsed] = useState(true); // Adding state to track collapse

    // Toggle the collapse state
    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light sticky-top navbar-padding-large-screen " style={{ backgroundColor: theme.navStyle.backgroundColor, height: '100px'}}>
            <a className="navbar-brand" href="/">
                <img src={images.logo} alt="logo" style={{ height: '80px' }} />
            </a>
            <button
                style={{ color: theme.navStyle.color, borderColor: theme.navStyle.color, height: '40px' }}
                className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`} // Toggling the class
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded={!isCollapsed}
                aria-label="Toggle navigation"
                onClick={toggleNavbar} // Handling click
            >
                <i className="fas fa-bars"></i> 
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav" style={{ backgroundColor: theme.navStyle.backgroundColor, paddingLeft: '30px', paddingRight: '30px'}}>
                <ul className="navbar-nav" >
                    <li className="nav-item">
                        <a className="nav-link" href="/" style={{ color: theme.navStyle.color, fontFamily: theme.fontFamily, fontWeight: 'bold', fontSize: '16px' }}>Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/products" style={{ color: theme.navStyle.color, fontFamily: theme.fontFamily, fontWeight: 'bold', fontSize: '16px' }}>Products</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact" style={{ color: theme.navStyle.color, fontFamily: theme.fontFamily, fontWeight: 'bold', fontSize: '16px' }}>Contact</a>
                    </li>
                </ul>            
            </div>
        </nav>
    );
};

export default Navbar;
